/* eslint-disable no-console */
import { isDevelopmentMode, isOnPremise } from 'appConfig';
import * as sentry from '@ardoq/sentry';
import { api } from '@ardoq/api';
import { ArdoqError, isArdoqError } from '@ardoq/common-helpers';

const mapToSentryLevel = (level: any) => {
  if (level === 'warn') {
    return 'warning';
  }
  return level;
};

type LogProps = {
  error: ArdoqError | Error;
  message?: string | null;
  extraData?: SentryFingerprint & Record<string | number, unknown>;
  level: string;
  logToSentry?: boolean;
  logToConsoleInDevMode?: boolean;
};

export const log = ({
  error,
  message,
  extraData = {},
  level = 'info',
  logToSentry = true,
  logToConsoleInDevMode = true,
}: LogProps) => {
  const isDev = isDevelopmentMode();
  const isOnPrem = isOnPremise();

  // Ignore 401 unauthorized api errors
  if (api.isUnauthorized(error)) {
    return;
  }

  // Log to console
  const actualLogToConsoleInDevMode =
    logToConsoleInDevMode && !String(message).includes('ErrorBoundary');
  if (isDev && actualLogToConsoleInDevMode) {
    if (level === 'error') {
      console.error(
        isArdoqError(error) ? error.error : error,
        message,
        extraData
      );
    } else {
      console.log(`[${level}]: ${message || ''}`, error);
    }
  }

  // Log to Sentry
  if (logToSentry && !isOnPrem) {
    sentry.log(error, message, extraData, mapToSentryLevel(level));
  }
};

window.logger = {
  log,
};
